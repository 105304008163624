<template>
    <div class="showcase pt-12 lg:pt-16 pb-12 lg:pb-12 lg:pb-0 bg-gray-200">
        <div class="container px-6 mx-auto flex flex-wrap justify-between items-center">
            <div class="w-full lg:w-1/3">
                <h2 class="uppercase text-gray-500 font-bold text-xs font-heading">The why</h2>
                <h3 class="text-3xl xl:text-4xl text-gray-800 font-heading font-semibold">You've got <span class="text-red-600">questions</span>.</h3>
                <h4 class="text-md lg:text-lg font-body text-gray-700 mb-5 lg:mb-0">Here are some of our answers</h4>
            </div>
            <div class="w-full lg:w-2/3">
                <p class="font-body text-sm lg:text-lg text-gray-600 leading-relaxed font-light">We've had the good fortune to work with plenty of exciting brands and individuals over the years. The showcase below is just a taste of some the questions we've helped answer. Can we <span class="font-normal text-gray-700">answer yours?</span></p>
            </div>
        </div>
        <div class="container mx-auto flex justify-center">
            <div class="showcase w-11/12 bg-white px-10 shadow-md flex flex-wrap mt-10">
                <div class="w-full flex justify-between items-center relative">
                    <div class="back absolute w-10 h-10 lg:w-12 lg:h-12 -left-12 md:-left-16 bg-red-600 text-red-100 transform rotate-45 flex justify-center items-center shadow-md">
                        <div class="transform -rotate-45">
                            <button @click="$refs.showcase.goToPrev()" class="flex justify-center items-center">
                                <i class="gg-arrow-left"></i>
                            </button>
                        </div>
                    </div>

                    <agile
                        ref="showcase"
                        :autoplay="true"
                        :autoplaySpeed="15000"
                        :navButtons="false"
                        :speed="1500"
                        class="lg:px-10 overflow-x-hidden flex"
                    >
                        <div class="slide w-full">
                            <div class="flex flex-wrap flex-col items-center justify-center lg:flex-row px-3 py-12">
                                <div class="lg:w-1/2">
                                    <div class="flex flex-col justify-center items-center pb-6 lg:pb-0">
                                        <img src="@/assets/img/showcase/showcase-asset-invest-02.png" class="max-w-32">
                                    </div>
                                </div>

                                <div class="lg:w-1/2 flex flex-col justify-center lg:pl-6">
                                    <h3 class="text-xl lg:text-2xl font-heading font-semibold text-gray-800">Startup Branding</h3>
                                    <h4 class="lg:text-lg mb-5 font-condensed text-gray-700">
                                        Asset Invest <span class="text-red-600 font-bold">|</span> <span class="text-sm uppercase text-gray-600">Real Estate</span>
                                    </h4>

                                    <p class="text-sm lg:text-base font-body text-gray-600 leading-relaxed mb-4">Asset Invest, a new company set up in Liverpool in 2019, specialise in the sale and development of regional corporate property.</p>

                                    <p class="text-sm lg:text-base font-body font-light text-gray-600 leading-relaxed mb-4">Requiring a simple but iconic brand for use on office premises, stationery and digital advertising media, we created their answer and delivered a finished product that delighted our client.</p>

                                    <p class="text-sm lg:text-base font-body font-light text-gray-600 leading-relaxed">The finished design features a simple icon made from a lowercase 'c' and 'i' bound by a circle.</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide w-full">
                            <div class="flex flex-wrap flex-col items-center justify-center lg:flex-row px-6 py-12">
                                <div class="lg:w-1/2">
                                    <div class="flex flex-col justify-center items-center pb-6 lg:pb-0">
                                        <img src="@/assets/img/showcase/showcase-lines-brew.jpg" class="max-w-32">
                                    </div>
                                </div>

                                <div class="lg:w-1/2 flex flex-col justify-center lg:pl-6">
                                    <h3 class="text-xl lg:text-2xl font-heading font-semibold text-gray-800">Drink Sleeve Design</h3>
                                    <h4 class="text-base lg:text-lg mb-5 font-condensed text-gray-700">
                                        Lines Brew Co. <span class="text-red-600 font-bold">|</span> <span class="text-sm uppercase text-gray-600">Alcoholic Beverages</span>
                                    </h4>

                                    <p class="text-sm lg:text-base font-body text-gray-600 leading-relaxed mb-4">Lines Brew Co. is a Welsh microbrewery based in the town of Usk near the English border, producing a range of alternative beers for a discerning younger market.</p>

                                    <p class="text-sm lg:text-base font-body font-light text-gray-600 leading-relaxed mb-4">Brain child of The Celt Experience's Tom Newman, Lines Brew Co. is his latest venture, combining the outlandish 'hipster' edge of his previous brands with exciting new flavours.</p>

                                    <p class="text-sm lg:text-base font-body font-light text-gray-600 leading-relaxed">Drawing upon Welsh folklore from the surrounding area, the brand's identity centres around mysticism and the migration of Celtic tribes and the magical powers of their gods and godesses.</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide w-full">
                            <div class="flex flex-wrap flex-col items-center justify-center lg:flex-row px-6 py-12">
                                <div class="lg:w-1/2">
                                    <div class="flex flex-col justify-center items-center lg:h-full pb-6 lg:pb-0">
                                        <img src="@/assets/img/product-design.jpg" class="max-w-32">
                                    </div>
                                </div>

                                <div class="lg:w-1/2 flex flex-col justify-center lg:pl-6">
                                    <h3 class="text-xl lg:text-2xl font-heading font-semibold text-gray-800">Product Design &amp; Rebranding</h3>
                                    <h4 class="text-base lg:text-lg mb-5 font-condensed text-gray-700">
                                        Cyclone London <span class="text-red-600 font-bold">|</span> <span class="text-sm uppercase text-gray-600">Luggage</span>
                                    </h4>

                                    <p class="text-sm lg:text-base font-body text-gray-600 leading-relaxed mb-4">Cyclone London are a luggage design and retail business based out of London.</p>

                                    <p class="text-sm lg:text-base font-body font-light text-gray-600 leading-relaxed mb-4">Wanting a new suitcase design incorporating schematics drawing on the lines of Japanase muscle cars, iNQUiSiTiON produced a range of seven; each boasting its own colour and theme.</p>

                                    <p class="text-sm lg:text-base font-body font-light text-gray-600 leading-relaxed">Each suitcase is branded with a new logo diecast in metal and set into the side of its shell.</p>
                                </div>
                            </div>
                        </div>
                    </agile>

                    <div class="forward absolute w-10 h-10 lg:w-12 lg:h-12 bg-red-600 text-red-100 transform rotate-45 flex justify-center items-center shadow-md -right-12 md:-right-16">
                        <div class="transform -rotate-45">
                            <button @click="$refs.showcase.goToNext()" class="flex justify-center items-center">
                                <i class="gg-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { VueAgile } from 'vue-agile'

    export default {
        name: 'Showcase',
        components: {
            agile: VueAgile
        },
    }
</script>

<style scoped>
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/arrow-left.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/arrow-right.css');

    ::v-deep .hooper {
        height: 100% !important;
    }
    ::v-deep .hooper-liveregion {
        display: none;
    }
</style>