<template>
    <div class="testimonials bg-red-700 px-6 lg:px-12 pt-16 pb-16 lg:pt-24 lg:-mt-24">
        <div class="container mx-auto">
            <div class="w-full text-center mb-10">
                <h2 class="uppercase text-red-300 font-bold text-xs font-heading">The who</h2>
                <h3 class="text-red-100 text-2xl lg:text-3xl font-bold font-heading">Don't take our word for it</h3>
                <h4 class="text-red-200 font-body font-light lg:text-xl mb-5">See what our clients have to say</h4>

                <div class="hidden md:flex md:justify-center w-full text-red-300 ">
                    <div class="w-6 h-6 -left-16 bg-red-500 bg-opacity-35 text-red-300 transform rotate-45 flex justify-center items-center shadow-md mr-2">
                        <div class="transform -rotate-45">
                            <button @click="$refs.testimonials.goToPrev()" class="flex justify-center items-center">
                                <i class="gg-arrow-left"></i>
                            </button>
                        </div>
                    </div>
                    <div class="w-6 h-6 -left-16 bg-red-500 bg-opacity-35 text-red-300 transform rotate-45 flex justify-center items-center shadow-md ml-2">
                        <div class="transform -rotate-45">
                            <button @click="$refs.testimonials.goToNext()" class="flex justify-center items-center">
                                <i class="gg-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <agile
                    ref="testimonials"
                    :options="options"
                    :speed="1500"
                >
                    <div class="slide">
                        <Testimonial client="fcl"/>
                    </div>
                    <div class="slide">
                        <Testimonial client="studio-dreamcatcher"/>
                    </div>
                    <div class="slide">
                        <Testimonial client="celt-brewery"/>
                    </div>
                    <div class="slide">
                        <Testimonial client="forest-housing"/>
                    </div>
                    <div class="slide">
                        <Testimonial client="synchrotrax"/>
                    </div>
                    <div class="slide">
                        <Testimonial client="drakenwerks"/>
                    </div>
                </agile>
            </div>
        </div>
    </div>
</template>

<script>
    import Testimonial from './Testimonial'
    import { VueAgile } from 'vue-agile'

    export default {
        name: 'Testimonials',
        components: {
            agile: VueAgile,
            Testimonial,
        },
        data() {
            return {
                options: {
                    autoplay: true,
                    autoplaySpeed: 15000,
                    navButtons: false,
                    slidesToShow: 1,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 2,
                            }
                        }
                    ]
                }
            };
        },
    }
</script>

<style scoped>
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/arrow-left.css');
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/arrow-right.css');

    .gg-arrow-left,
    .gg-arrow-right {
        --ggs: .75;
    }

    ::v-deep .agile__slides {
        @apply flex items-start;
    }
</style>