<template>
    <div class="quote absolute -mt-16 w-screen">
        <div class="container mx-auto flex justify-center">
            <div class="bg-red-gray-500 p-6 lg:w-9/12 xl:w-7/12">
                <div class="flex flex-col lg:flex-row justify-center items-center text-center lg:text-left">
                    <div class="w-full lg:w-1/2 xl:w-4/12 flex flex-col mb-4">
                        <span class="text-red-100 font-bold font-condensed text-3xl">Feeling <span class=" text-red-100">inspired?</span></span>
                        <span class="text-gray-400 text-xl font-heading font-light">Let's create your <span class="text-red-600 font-bold">answer</span></span>
                    </div>
                    <div class="w-full xl:w-8/12 flex flex-col lg:flex-row justify-between items-center mb-0 lg:mb-2 xl:ml-3">
                        <button
                            @click="openContactForm" 
                            class="bg-gray-100 hover:bg-gray-300 text-red-600 font-semibold font-condensed hover:text-gray-700 py-3 px-4 border border-red-200 hover:border-transparent w-full" type="button">
                            Get a Quote
                        </button>
                        <span class="inline-block w-2/12 text-center text-gray-400 lg:text-red-200 font-semibold font-condensed text-sm px-2 pt-2 pb-2 lg:pt-0 lg:pb-0 uppercase">or</span>
                        <a href="tel:447736933897" class="bg-red-600 hover:bg-red-800 text-white hover:text-red-200 font-bold py-3 px-4 focus:outline-none focus:shadow-outline font-condensed w-full text-center" type="button">
                            Let's have a chat
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ContactForm from '@/components/forms/ContactForm'
    import { ModalBus } from '@/eventBus'

    export default {
        name: 'quote',
        components: {},
        methods: {
            openContactForm() {
				ModalBus.$emit('open', { component: ContactForm })
			}
        },
    }
</script>