<template>
    <div class="testimonial w-full p-3">
        <div class="p-6 bg-red-800">
            <p class="text-red-300 text-sm lg:text-base leading-relaxed font-condensed">{{ testimonial.testimonial }}.</p>
        </div>

        <div class="py-5 px-3">
            <div class="client flex flex-wrap">
                <div class="client-image w-12 h-12 bg-gray-800 transform rotate-45" :class="testimonial.avatar">

                </div>
                <div class="client-details ml-4">
                    <p class="font-condensed font-bold text-red-200">{{ testimonial.name }}</p>
                    <p class="font-condensed text-sm upp text-red-300">{{ testimonial.company }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Testimonial',
        props: {
            client: {
                type: String,
            }
        },
        computed: {
            testimonial () {
                switch (this.client) {
                    case 'celt-brewery':
                        return {
                            company: 'Celt Brewery',
                            name: 'Tom Newman',
                            testimonial: "We were delighted by Inquisition's work as consultants overseeing the rebranding of our large range of beers. They sent one of their people to work on site at our premises in Caerphilly for two months and the results were fantastic. They were very easy to work with and highly professional.",
                            avatar: 'celt'
                        }
                    case 'drakenwerks':
                        return {
                            company: 'DrakenWerks',
                            name: 'SP Draken',
                            testimonial: "As a rising music artist, I required professional produced album artwork for a new release. Inquisition more than delivered, working well to our brief and keeping up good communication throughout the process. The final product was completed to an outstanding level and finished well ahead of the set deadline. They are good value for money and I will be using their services again in the future.",
                            avatar: 'drakenwerks'
                        }
                    case 'fcl':
                        return {
                            company: 'FitzGerald Consulting Limited',
                            name: 'Gerald Williams',
                            testimonial: "As a global Loss Adjusting and Insurance Claims Consultancy practice based in London and Latin America, we needed to have our web presence optimised and sought the help of a competent and professional SEO specialist. Inquisition proved to be fantastic.  They explained everything very clearly and were available throughout the process to answer our questions. Overnight our Google rankings improved and our website is now number one for our chosen search terms. Thank you Inquisition!",
                            avatar: 'fcl'
                        }
                    case 'forest-housing':
                        return {
                            company: 'Forest Housing Association',
                            name: 'Keith Salter',
                            testimonial: "After their initial site visit, they prepared a range of aesthetic impressions for the new look, and we then hired them to project manage the building process.They were highly efficient, easy to work with, and we would recommend them to anyone thinking of undertaking a similar project in the future.",
                            avatar: 'forest'
                        }
                    case 'studio-dreamcatcher':
                        return {
                            company: 'Studio Dreamcatcher',
                            name: 'Gregor Beyerle',
                            testimonial: "Studio Dreamcatcher is a start-up company based here in Germany. After hiring Inquisition to generate a website and a logo, we found them to be extremely pleasant to work with. They have a real \"can do\" attitude and made every effort to clearly understand our business and our ethos.  The resulting website was exactly what we had in mind, and was completed before the deadline we set. We would recommend Inquisition to any business who needed similar services in the future.",
                            avatar: 'dreamcatcher',
                        }
                    case 'synchrotrax':
                        return {
                            company: 'Synchrotrax Events - Machine Rox',
                            name: 'Richard Kaltenhauser',
                            testimonial: "Inquisition have been working on concert posters for Synchrotrax Events and CD sleeves for the band Machine Rox for the past 4 years. They've always delivered outstanding work and have always been fast, efficient and highly professional. They've been easy to work with and always created fantastic artwork. We've always been completely satisfied with the result of their work. I would highly recommend them to anyone looking for a designer.",
                            avatar: 'synchrotrax',
                        }
                    default:
                        throw new Error(this.client + ' is invalid.')
                }
            }
        }
    }
</script>

<style scoped>
    .client-image {
        @apply relative overflow-hidden;
    }

    .client-image:before {
        @apply bg-white w-20 h-20 transform -rotate-45;
        content: '';
        display: block;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: -1rem;
        right: 0;
        bottom: 0;
        left: -1rem;
    }
    .client-image.celt:before {
        background-image: url(~@/assets/img/testimonials/celt.png);
    }
    .client-image.dreamcatcher:before {
        background-image: url(~@/assets/img/testimonials/dreamcatcher.png);
    }
    .client-image.fcl:before {
        background-image: url(~@/assets/img/testimonials/fcl.jpg);
    }
    .client-image.forest:before {
        background-image: url(~@/assets/img/testimonials/forest.png);
    }
    .client-image.synchrotrax:before {
        background-image: url(~@/assets/img/testimonials/syncrotrax.png);
    }
</style>